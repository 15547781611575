@use "sass:math";

$col-4-size: 33.33333333%;
$col-5-size: 41.66666667%;
$col-6-size: 50%;
$col-7-size: 58.33333333%;
$col-12-size: 100%;

.opd-layout-box {
  display: flex;
  // height: 100%;
  flex: 1;
  flex-direction: column;

  @include media-breakpoint-up(xl) {
    flex-direction: row;

    .opd-layout-body {
      width: 50%;
    }
  }

  .opd-layout-body {
    flex: 1;

    .opd-card-header {
      padding: 0.75rem 1rem;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      background-color: $white;

      &.no-border-top {
        border-top: none;
      }

      @include media-breakpoint-up(xl) {
        background-color: $white;
        border-top-width: 0px;
        border-bottom: 1px solid $border-color;
      }
    }

    .opd-card-body {
      .opd-section {
        padding: 1rem;
      }

      .highlight {
        padding: 0.5rem;
        margin: -0.5rem;
        background-color: rgba($primary, 0.1);
        border-radius: $border-radius;
      }
    }

    &.full-size {
      width: 100%;
    }

    &:first-child {
      @include media-breakpoint-up(xl) {
        border-right: 1px solid $border-color;
      }
    }

    &.bg-gray {
      background-color: $gray-50;
    }

    &.bg-xs-gray {
      background-color: $gray-50;

      @include media-breakpoint-up(sm) {
        background-color: transparent;
      }
    }

    &.fix-size {
      @include media-breakpoint-up(xl) {
        flex: 0 0 500px;
      }
    }

    &.no-border-right {
      border-right: none;
    }
  }
}

.opd-footer {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &>button {
    width: calc(50% - 0.5rem);

    @include media-breakpoint-up(sm) {
      width: initial;
    }
  }

  .btn-fw {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: initial;
    }
  }

  &.sticky-bottom {
    position: sticky;
    z-index: 10;
    bottom: 0;
    background-color: $white;
  }
}

.table-bill-items {
  thead {
    th {
      padding: 0.75rem 1rem;
      border-top: none;
      border-bottom: none;
    }
  }

  tbody {
    tr {
      &.valign-top {
        td {
          vertical-align: top;
        }
      }
    }

    td {
      border-top: none;
      padding: 0.5rem 1rem;
    }
  }

  &.layout-2 {
    border-collapse: separate;
    border-spacing: 0 0.75rem;

    thead {
      th {
        padding: 0 0.5rem;
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
        border: none;
        background-color: transparent !important;
        letter-spacing: 0;
      }
    }

    tbody {
      tr {
        height: 1px;

        &.edit-row {
          td {
            border-top-color: $primary;
            border-bottom-color: $primary;
            padding-top: calc(0.75rem + 20px);

            &:first-child {
              border-left-color: $primary;
              padding-top: calc(0.75rem + 20px) !important;
            }

            &:last-child {
              border-right-color: $primary;
            }
          }
        }

        &.error-row {
          td {
            border-top-color: $red;
            border-bottom-color: $red;

            &:first-child {
              border-left-color: $red;
            }

            &:last-child {
              border-right-color: $red;
            }
          }
        }
      }

      td {
        height: inherit;
        padding: 0.75rem 0.5rem;
        transition: border-color 0.2s ease-in-out;

        &:not(.empty-row) {
          background: $white;
          border: 1px solid $border-color;
          border-left-width: 0px;
          border-right-width: 0px;

          *:not(.mobile-label, .code) {
            font-family: $font-family-th-data;
          }
        }

        &:first-child {
          border-top-left-radius: 0.375rem !important;
          border-bottom-left-radius: 0.375rem !important;
          border-left-width: 1px;
        }

        &:last-child {
          border-top-right-radius: 0.375rem !important;
          border-bottom-right-radius: 0.375rem !important;
          border-right-width: 1px;
        }

        &.empty-row {
          color: $text-muted;
          text-align: center !important;
          border: none;
        }

        &.action-cell {
          padding: 0;
          border-left-width: 1px;

          div {
            height: 100%;
          }

          .btn-icon {
            padding: 0.25rem;

            .feather {
              width: 20px;
              height: 20px;
            }

            &.h-17 {
              .feather {
                height: 17px;
              }
            }
          }
        }

        .value-spacing {
          display: block;
          padding: 0.6rem 0;
        }
      }
    }
  }

  &.table-mobile-layout {
    @include media-breakpoint-down(sm) {
      display: block;

      thead {
        display: none;
      }

      tbody {
        display: flex;
        width: 100%;
        flex-direction: column;

        tr {
          height: unset;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto 1fr;
          grid-column-gap: 0px;
          grid-row-gap: 0px;
          margin-bottom: 0.75rem;
          border-radius: 0.375rem;
          border: 1px solid $border-color;
          background-color: $white;
          transition: border-color 0.2s ease-in-out;
          overflow: hidden;

          td {
            border-left: none !important;
            border-right: none !important;
            border-bottom: none !important;
            border-top: 1px solid $border-color;
            border-radius: 0;
            background-color: transparent;
            text-align: left;
            padding: 0.75rem;

            &.cw-1 {
              grid-column: 1 / span 2;
              border-top: none;
            }

            &.bd-r {
              border-right: 1px solid $border-color !important;
            }

            &.bd-t {
              border-top: 1px solid $border-color !important;
            }

            &.no-bd-b {
              border-bottom: 0px !important;
            }

            &.action-cell {
              padding: 0.25rem 0.75rem;
              border-radius: 0px !important;
              border-top: 1px solid $border-color !important;
            }

            .value-spacing {
              padding: 0;
            }

            .mobile-label {
              color: $text-muted;
              font-size: $font-size-sm;
            }
          }

          &.edit-row {
            border-color: $primary !important;

            td {
              padding-top: 0.25rem;
              border-top-color: $border-color !important;
              border-bottom-color: $border-color !important;
              border-left-color: $border-color !important;
              border-right-color: $border-color !important;
            }
          }
        }
      }
    }
  }
}

.item-meta {
  display: flex;

  p {
    margin: 0;
  }

  .data {
    flex: 1;
  }

  .code {
    font-family: $font-family-en;
  }

  .label {
    margin-top: 0.5rem;
    font-size: calc($font-size-base - 1px);
  }
}

.code {
  font-size: 0.857rem;
  color: $text-muted;
  line-height: $line-height-sm;
}

.med-label-editable {
  border-radius: $border-radius;
  background-color: rgba($warning, 0.1);
  padding: 0.357rem 0.75rem;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: $font-weight-bold;
  width: fit-content;

  .feather {
    color: $warning;
    margin-right: 0.5em;
  }
}

.item-discount {
  display: flex !important;
  justify-content: space-between;

  p {
    margin: 0;
  }
}

.table-discount {
  .ng-select {
    min-width: 50px;

    .ng-select-container {
      .ng-value-container {
        padding: 0 0.5rem !important;
        justify-content: center;
      }

      .ng-input {
        padding: 0 0.5rem !important;
      }
    }

    .ng-arrow-wrapper {
      display: none;
    }
  }
}

.bill-summary {
  margin-left: auto;
  padding: 0 0.75rem;

  @include media-breakpoint-up(sm) {
    width: 250px;
  }

  .item {
    display: flex;
    justify-content: space-between;

    p {
      margin: 0;
    }
  }
}

.opd-tab-content {
  border: 1px solid $border-color;
  border-radius: 0 0 $border-radius $border-radius;
}

.fill-tab-pane {
  .tab-pane {
    height: 100%;
  }
}

.flex-tab-pane {
  display: flex;
  flex-direction: column;
  flex: 1;

  .tab-pane {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.filter-box {
  padding: 0.5rem 1rem;
  background-color: $gray-50;

  .custom-label {
    margin-right: 5px;
    text-align: right;
    width: 60px;
  }

  app-daterange-picker,
  .in-filter-box {
    width: 100%;
  }
}

// MED LABEL
.med-label-wrapper {
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  .med-label-form {
    flex: 1;
  }

  .med-label-preview {
    background-color: $gray-50;

    .med-label-sticker-preview {
      margin: 0 auto;
      width: 100%;
      border-radius: $border-radius;
      border: 4px solid $primary;
      font-size: $font-size-sm;
      color: $black;
      background-color: $white;

      @include media-breakpoint-up(sm) {
        width: 320px;
      }

      .text {
        font-family: $font-family-th-data;
        line-height: 1.4;
      }

      .med-label-sticker-header {
        padding: 0.5rem 0.75rem;
        background-color: rgba($primary, 0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .brand-logo {
          height: 22px;
          width: auto;
        }
      }

      .med-label-sticker-body {
        padding: 0.5rem 0.75rem;
        font-family: $font-family-th-data;

        .line-text {
          display: flex;
          justify-content: space-between;

          p {
            margin-bottom: 0;
            line-height: 1.4;

            &.exp-date {
              font-size: 0.75rem;
            }

            &._blank {
              opacity: 0;
            }
          }

          &.med-title {
            font-size: $font-size-base;
            font-weight: $font-weight-bold;

            P {
              line-height: 1.2;
            }
          }

          &.med-route {
            margin-top: 0.375rem;
            flex-direction: column;
            font-size: $font-size-base;
          }

          &.med-caution {
            flex-direction: column;
          }
        }
      }
    }
  }

  .med-section {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid $border-color;
  }

  .custom-inline-input {
    display: flex;
    align-items: center;

    label {
      width: 80px;
    }

    label+* {
      width: 100%;
    }
  }

  label {
    margin-bottom: 0px;
  }
}

.detail-list {
  list-style: none;
  margin-bottom: 0;
  padding: 0;

  li {
    position: relative;
    padding-left: 0.75rem;

    &::after {
      content: "-";
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

opd-card-history {
  height: 100%;

  .history-content-wrapper {
    overflow: hidden;
    border-radius: $card-border-radius;

    .history-wrapper {
      height: 100%;
      display: flex;
      align-items: stretch;

      .left-bar {
        width: 0;
        display: none;
        background-color: $gray-50;
        border-right: 0px solid $border-color;

        @include media-breakpoint-up(sm) {
          display: block;
          width: 180px;
          border-right: 1px solid $border-color;
        }
      }

      .right-bar {
        flex: 1;
      }
    }
  }
}

// Split box
.split-box {
  transition-duration: 0.4s;
  flex: 0 0 100%;
  height: 100%;

  &.shrink,
  &.shrinked {
    flex: 0 0 calc(100%);

    @include media-breakpoint-up(xl) {
      flex: 0 0 calc($col-5-size - 0.5rem);
    }

    @include media-breakpoint-up(xxl) {
      flex: 0 0 calc($col-6-size - 0.5rem);
    }
  }

  &.expand,
  &.expanded {
    flex: 0 0 100%;
  }

  &.box-collapse {
    flex: 0;

    &.collapse-out {
      height: unset;
    }

    &.collapsed {
      flex: 0 0 0 !important;
      height: 0 !important;
      margin-left: 0rem !important;
      overflow: hidden;
    }

    &.collapse-in,
    &.show {
      flex: 0 0 calc(100%);
      height: unset;

      @include media-breakpoint-up(xl) {
        flex: 0 0 calc($col-7-size - 0.5rem);
      }

      @include media-breakpoint-up(xxl) {
        flex: 0 0 calc($col-6-size - 0.5rem);
      }
    }

    &.show {
      height: 100%;
    }
  }

  .split-box-toggle {
    opacity: 1;
    transition: opacity 0.2s;

    &.toggling {
      opacity: 0;
    }

    &.toggling-delay {
      transition-delay: 0.2s;
    }
  }
}

.split-box {
  @include media-breakpoint-up(xl) {
    &.shrinked {
      .opd-layout-box {
        flex-direction: column;

        .opd-layout-body {
          width: 100%;

          &:first-child {
            border-right: none;
          }

          &.fix-size {
            flex: 1;
          }
        }

        .form-wrapper {
          .item-shink-mb {
            margin-bottom: 1rem !important;
          }

          .item-shink-6 {
            flex: 0 0 $col-6-size;
            max-width: $col-6-size;
          }

          .item-shink-12 {
            flex: 0 0 $col-12-size;
            max-width: $col-12-size;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    &.shrinked {
      .opd-layout-box {
        .form-wrapper {
          .item-shink-xxl-mb-0 {
            margin-bottom: 0rem !important;
          }

          .item-shink-xxl-4 {
            flex: 0 0 $col-4-size;
            max-width: $col-4-size;
          }

          .item-shink-xxl-6 {
            flex: 0 0 $col-6-size;
            max-width: $col-6-size;
          }
        }
      }
    }
  }
}

// VN LIST
.clickable-list-wrapper {
  max-height: 950px;
  overflow: auto;

  .clickable-list {
    padding: 0.25rem 0 0 0;
    margin: 0;
    list-style: none;

    li {
      cursor: pointer;
      transition: all 0.3s, border-color 0s;
      position: relative;
      padding: 0.575rem 1.5rem;
      border-bottom: 1px solid $border-color;

      &:hover,
      &.selected {
        transform: translateY(-3px);
        background-color: $white;
        transition: all 0.3s;
        box-shadow: 0 3px 2px 0 $border-color;
      }

      .title-wrapper {
        font-family: $font-family-th-data;

        .todo-title {
          font-weight: $font-weight-bold;
          margin-bottom: 0;
        }

        span {
          display: block;
          font-size: $font-size-sm;
          color: $text-muted;
          line-height: $line-height-sm;
        }
      }
    }
  }
}

.file-list-wrapper {
  .file-item {
    display: flex;
    align-items: center;
    background-color: $white;
    padding: 0.75rem;
    border-radius: $border-radius-sm;
    border: 1px solid $border-color;

    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }

    &.active {
      border-color: $success;
      background-color: mix($white, $success, 90%);
    }

    &.active-border {
      border-color: $success;
    }

    &.error {
      border-color: $brand-sub;
      background-color: mix($white, $brand-sub, 95%);
    }

    &.warning {
      border-color: $warning !important;
      background-color: mix($white, $warning, 98%);
    }

    &.danger {
      border-color: $danger;
    }

    &.has_file {
      border-color: $primary-sub;
    }

    &.bordered {
      border-color: $gray-500;
    }

    .file-body {
      flex: 1;

      .file-action {
        display: flex;
        align-items: center;
      }
    }

    .file-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .file-checkbox-margin {
      margin-left: 2px;
      padding-left: 1.5rem;
    }

    .file-body+.file-right {
      border-left: 1px solid $border-color;
    }
  }

  .file-date {
    font-size: $font-size-sm;
    color: $text-muted;
  }

  .file-box-stretch {
    .file-item {
      height: 100%;
      align-items: flex-start;
    }
  }
}

.file-style-box {
  text-align: left;
  display: block;
  padding: 0.5rem;
  background-color: mix($white, $primary, 95%);
  border-radius: $border-radius;
  border: 1px solid mix($white, $primary, 50%);
  font-size: $font-size-sm;
  color: $primary;
  line-height: 1.2;

  &.existing {
    border-color: $gray-400;
    background-color: $gray-50;
    color: $black;

    &:hover {
      text-decoration: underline;
    }
  }

  &:not(.existing):hover {
    background-color: mix($white, $primary, 90%);
  }

  .remove-file {
    color: $danger;
    position: absolute;
    right: 0.5rem;
    top: 8px;
  }
}

.edit-banner {
  position: absolute;
  width: calc(100% - 2px);
  top: 1px;
  left: 1px;
  height: 24px;
  padding: 0 6px;
  line-height: 24px;
  font-size: $font-size-sm;
  color: $primary;
  border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
  background-color: mix($primary, $white, 10%);
  border-bottom: 1px solid $primary;
  font-family: $font-family-th !important;
}