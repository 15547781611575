@import '@core/scss/base/colors';
@import '@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '@core/scss/base/components/include'; // Bootstrap includes

// variable declaration
$date-time-picker-weekdays-color: $gray-700;
$date-time-picker-days-bg: $white;
$date-time-picker-days-color: $gray-900;

$date-time-picker-dark-bg-color: $theme-dark-body-bg;
$date-time-picker-dark-text-color: $theme-dark-body-color;
$date-time-picker-dark-day-hover: $gray-700;

pre {
  color: $white;
}

[ngbdatepickerdayview] {
  &.btn-light {
    cursor: pointer !important;
    color: $date-time-picker-days-color !important;
    background-color: $date-time-picker-days-bg !important;
    border-radius: 2px;
    line-height: 1.875rem !important;

    &:focus {
      outline: 0;
      outline-width: 0;

      &:active {
        box-shadow: none !important;
      }
    }

    &.active {
      border: 0;
    }

    &:hover {
      background-color: $gray-100 !important;
    }

    &.bg-primary {
      background-color: $primary !important;
      color: $white !important;

      &:hover {
        background-color: primary !important;
      }
    }
  }
}

ngb-datepicker-navigation {
  padding: 0.25rem 0;
}

ngb-datepicker-month {
  padding: 0.571rem 0.857rem;
}

.w-0 {
  width: 0 !important;
}

.ngb-dp-header,
.ngb-dp-weekdays,
.ngb-dp-month-name,
.ngb-dp-day {
  background-color: $date-time-picker-days-bg !important;
}

.ngb-dp-day {
  &.ngb-dp-today {
    .btn-light {
      border: 1px solid $primary !important;
    }
  }
}

.ngb-dp-weekday {
  color: $date-time-picker-weekdays-color !important;
  background-color: $white !important;
  font-size: 1rem;
}

.custom-day {
  text-align: center;
  padding: 0.7rem 0.25rem;
  display: inline-block;
  height: 2.5rem;
  width: 2.5rem;

  &.bg-primary {
    background-color: $primary !important;
    color: $white !important;
  }
}

.custom-day.focused {
  background-color: $primary;
}

.custom-day.range,
.custom-day:hover {
  background-color: $primary;
  color: $white;
}

.custom-day.faded {
  background-color: rgba($primary, 0.5);
}

.weekend {
  background-color: $warning;
  color: $white;
}

.ngb-dp-day,
.ngb-dp-week-number,
.ngb-dp-weekday {
  height: unset !important;
  width: unset !important;
  min-width: 2.571rem;
  min-height: 2rem;
  padding: 3px 0;
  display: flex;
  justify-content: center;
}

.ngb-dp-week-number,
.ngb-dp-weekday {
  font-style: normal !important;
  font-weight: 500 !important;
}

.ngb-dp-weekdays {
  // padding-top: 0.4rem;
  border-bottom: none !important;
}

.ngb-dp-week {
  padding: 0 !important;
}

.custom-select {
  font-size: 1rem !important;

  &[aria-label='Select month'] {
    margin-right: 7px;
  }
}

.ngb-dp-header {
  padding-top: 0rem !important;
  border-bottom: 1px solid #f0f0f0 !important;
}

.ngb-dp-day {
  background-color: $date-time-picker-days-bg;

  &:focus {
    outline: none;
  }
}

.btn-link {
  color: $gray-500;
}

.ngb-dp-arrow-btn:focus {
  outline-style: none !important;
}

.ngb-dp-navigation-chevron {
  width: 0.5em !important;
  height: 0.5em !important;
  border-width: 0.1em 0.1em 0 0 !important;
}

// dark layout style
.dark-layout {

  .ngb-dp-header,
  .ngb-dp-weekday {
    background-color: $date-time-picker-dark-bg-color !important;
  }

  [ngbdatepickerdayview].btn-light {
    background-color: $date-time-picker-dark-bg-color !important;
    color: $date-time-picker-dark-text-color !important;

    &:hover {
      background-color: $date-time-picker-dark-day-hover !important;
      box-shadow: none !important;
    }

    &.bg-primary {
      background-color: $primary !important;
    }
  }

  .ngb-dp-week.ngb-dp-weekdays {
    background-color: $date-time-picker-dark-bg-color !important;
  }

  .ngb-dp-day {
    background-color: $date-time-picker-dark-bg-color !important;
  }
}