// Grid
@import "./grid.scss";

// COLORS
.bg-transparent {
  background-color: transparent !important;
}

.bg-light-blue {
  background-color: rgba($blue, 0.12);
  color: $blue;
}

.bg-light-blue-700 {
  background-color: rgba($blue-700, 0.12);
  color: $blue-700;
}

.bg-light-purple {
  background-color: rgba($purple, 0.12);
  color: $purple;
}

.bg-gray-50 {
  background-color: $gray-50;
}

.bg-gray-200 {
  background-color: $gray-200;
}

.d-contents {
  display: contents;
}

// BORDERS
.border-none {
  border: none !important;
}

.border-bottom-none {
  border-bottom: none !important;
}

@include media-breakpoint-up(sm) {
  .border-sm-right {
    border-right: 1px solid $border-color;
  }
}

@include media-breakpoint-up(xl) {
  .border-right-xl {
    border-right: 1px solid $border-color;
  }

  .border-bottom-xl-none {
    border-bottom: none !important;
  }

  .border-xxl-left {
    border-left: 1px solid $border-color;
  }
}

// SIZING
.w-fit {
  width: fit-content;
}

.min-w-fit {
  min-width: fit-content;
}

@include media-breakpoint-up(md) {
  .w-md-auto {
    width: auto !important;
  }

  .w-md-fit {
    width: fit-content !important;
  }
}

// FLEX
.flex-1 {
  flex: 1 !important;
}

@include media-breakpoint-up(md) {
  .flex-md-auto {
    flex: auto !important;
  }

  .flex-md-0 {
    flex: 0 !important;
  }

  .flex-md-1 {
    flex: 1 !important;
  }
}

@include media-breakpoint-up(lg) {
  .flex-lg-1 {
    flex: 1;
  }
}

@include media-breakpoint-up(xl) {
  .flex-xl-0 {
    flex: 0 !important;
  }

  .flex-xl-1 {
    flex: 1;
  }
}

// HEIGHT
.h-1 {
  height: 1px;
}

.h-fit {
  height: fit-content;
}

.h-full-page {
  height: calc(100dvh - $navbar-height - (1rem * 2));

  &.only-nav {
    height: calc(100dvh - $navbar-height);
  }
}

.min-h-full-page {
  min-height: calc(100dvh - $navbar-height - (1rem * 2));

  &.only-nav {
    min-height: calc(100dvh - $navbar-height);
  }
}

.min-h-75px {
  min-height: 75px !important;
}

.min-h-150px {
  min-height: 150px !important;
}
.min-h-200px {
  min-height: 200px !important;
}

.min-h-250px {
  min-height: 250px !important;
}
.min-h-400px {
  min-height: 400px !important;
}

.min-h-500px {
  min-height: 500px !important;
}
.min-h-70vh {
  min-height: 70vh !important;
}
.min-h-80vh {
  min-height: 80vh !important;
}
.min-h-85vh {
  min-height: 100% !important;
}
.min-h-90vh {
  min-height: 90vh !important;
}

.max-h-480px {
  max-height: 480px;
}

.max-h-640px {
  max-height: 640px;
}

.max-h-740px {
  max-height: 740px;
}
.max-h-70 {
  max-height: 70vh;
}

.min-h-full {
  min-height: 100%;
}

@include media-breakpoint-up(lg) {
  .h-lg-auto {
    height: auto !important;
  }

  .min-h-lg-150px {
    min-height: 150px !important;
  }
}

@include media-breakpoint-up(xl) {
  .h-xl-100 {
    height: 100%;
  }

  .h-xl-auto {
    height: auto !important;
  }

  .h-full-page-xl {
    height: calc(100dvh - $navbar-height - (1rem * 2));
  }

  .max-h-xl-unset {
    max-height: unset;
  }
}

// WIDTH
.max-w-1000px {
  max-width: 1000px;
}

@include media-breakpoint-up(sm) {
  .w-sm-auto {
    width: auto !important;
  }
}

// BORDER
// @include media-breakpoint-up(lg) {
//   .border-right-lg-none {
//     border-left: none;
//   }
//   .border-lg-none{
//     border: none;
//   }

// }

// OVERFLOW
.overflow-visible {
  overflow: visible;
}
@include media-breakpoint-up(md) {
  .overflow-md-auto {
    overflow: auto;
  }

  .overflow-md-hidden {
    overflow: hidden;
  }
  .overflow-md-visible {
    overflow: visible;
  }
}
@include media-breakpoint-up(xl) {
  .overflow-xl-auto {
    overflow: auto;
  }

  .overflow-xl-hidden {
    overflow: hidden;
  }
}

// POSITION
.centerize {
  display: flex;
  align-items: center;
  justify-content: center;
}

@include media-breakpoint-up(xl) {
  .position-xl-absolute {
    position: absolute;
  }
}

// ALIGNMENT
.alignment-btn-error-space {
  margin-bottom: 22px;
}

@include media-breakpoint-up(md) {
  .alignment-md-btn-error-space {
    margin-bottom: 22px;
  }
}

// FONT
.bold-700 {
  font-weight: 700;
}

.font-size-base {
  font-size: $font-size-base;
}

.font-size-sm {
  font-size: $font-size-sm;
}

.font-en {
  font-family: $font-family-en !important;
}

.font-th-data {
  font-family: $font-family-th-data !important;
}

.text-base {
  color: $body-color !important;
}

.object-cover {
  object-fit: cover;
}

.object-top {
  object-position: top;
}

.nowrap {
  white-space: nowrap;
}

.border-top-none {
  border-top: none !important;
}
.text-hover-primary:hover {
  color: $primary !important;
}
.d-invisible {
  opacity: 0;
  pointer-events: none;
}

.custom-overflow {
  .mat-dialog-container {
    overflow: visible !important;
  }
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.no-hover:hover {
  pointer-events: none !important;
  background-color: inherit;
}
.cursor-unset {
  cursor: unset !important;
}
