// Loading
#loading-bg {
  width: 100%;
  height: 100%;
  background: #fff;
  display: block;
  position: fixed;
  z-index: 99999;
  pointer-events: none;
  top: 0;
  left: 0;
}

status-loading {
  &:has(:not(.box-mode).on-load) {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
  }
}

.loading-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  border-radius: $border-radius-xl;
  box-shadow: $box-shadow-lg;
  background-color: $white;

  &.no-box {
    padding: 0;
    box-shadow: none;
  }

  .loading-logo {
    margin-bottom: 2rem;

    img {
      width: 80px;
    }
  }
}

.loading {
  position: relative;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
  margin: 0 auto;

  .effect-1,
  .effect-2 {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-left: 3px solid rgba($primary, 1);
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .effect-3 {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-left: 3px solid rgba($primary, 1);
    -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
    animation: rotateOpacity 1s ease infinite 0.2s;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .effect-1 {
    animation: rotate 1s ease infinite;
  }

  .effect-2 {
    animation: rotateOpacity 1s ease infinite 0.1s;
  }

  .effect-3 {
    transition: all 0.3s ease;
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }

  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}

// BUTTON
.btn-dropdown {
  border: 1px solid $gray-400;

  &:hover {
    background-color: $gray-100;
  }
}

// GRID
.row {
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  div[class^="col"],
  div[class^="col-"] {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

// Custom Component
.daterange-wrapper {
  display: flex;
  width: 100%;

  // @include media-breakpoint-up(md) {
  //   width: 320px;
  // }

  // @include media-breakpoint-up(xxl) {
  //   width: 360px;
  // }

  .input-group {
    flex: 1;

    .form-control {
      width: 100% !important;
      padding-right: 0px;
      border-right: none;
    }

    .input-group-text {
      padding-left: 0px;
    }

    &:first-child {
      .input-group-text {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child {
      .form-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .addon {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    background-color: $custom-2;
    line-height: 1;
  }
}

.no-chart {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $gray-100;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $secondary;
  border-radius: 0.5rem;
}

.chartbox {
  height: 300px;
  cursor: pointer;

  &.pie-box {
    width: 280px;
    height: 280px;
  }
}

.pie-legend {
  .chart-legend {
    flex-direction: column;

    .legend-item {
      cursor: default !important;
    }
  }
}

.chart-legend {
  display: flex;
  list-style: none;
  padding: 0;
  row-gap: 0.25rem;
  column-gap: 1rem;
  margin-bottom: 0;
  width: 100%;
  flex-wrap: wrap;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;

  .legend-color-box {
    width: 14px !important;
    height: 14px !important;
    border-radius: 0.25rem;
    border: 1px solid transparent;
    margin-bottom: 1px;
  }

  .legend-label-text,
  .legend-label-suffix {
    font-size: 15px;
    white-space: nowrap;
  }

  .legend-label-text {
    flex: 1;
  }

  .legend-label-value {
    white-space: nowrap;
    font-weight: 500;
    font-size: 18px;
  }
}

.skeleton-parent {
  ._base {
    display: block;
    background-color: rgba($gray-300, 0.75);
    border-radius: $border-radius-sm;
    color: transparent !important;
    animation: skeleton-loading 1s linear infinite alternate;
  }

  .card-text {
    @extend ._base;
    width: 60px;
    height: 22.5px;
  }

  h2 {
    @extend ._base;
    width: 100px;
    height: 31.5px;
  }

  // h3 {
  //   @extend ._base;
  //   width: 120px;
  //   height: 20px;
  // }

  // .skeleton-icon {
  //   @extend ._base;
  //   margin: auto !important;
  //   width: 19px;
  //   height: 20px;
  // }
}

.profile-meta {
  display: flex;
  width: 100%;

  .image-avatar {
    width: 115px;
    height: 115px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: $border-radius;
    margin: 0 auto 1rem auto;
    background-color: $gray-100;

    @include media-breakpoint-up(sm) {
      margin: 0 1rem 0 0;
    }

    @include media-breakpoint-up(xl) {
      width: 130px;
      height: 130px;
    }

    &.md {
      width: 110px;
      height: 110px;
    }

    &.collape-size {
      @include media-breakpoint-down(md) {
        width: 75px;
        height: 75px;
      }

      @include media-breakpoint-down(sm) {
        width: 110px;
        height: 110px;
      }
    }

    &.avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $gray-100;

      .avatar-content {
        width: 100%;
        height: 100%;
        font-size: 3rem;
        font-weight: $font-weight-bold;
      }
    }
  }

  .meta {
    flex: 1;

    h2,
    h6 {
      text-align: center;

      @include media-breakpoint-up(sm) {
        text-align: left;
      }
    }

    .font-size-id {
      font-size: 1.07rem;
    }

    .btn {
      width: 100%;
      height: fit-content;

      @include media-breakpoint-up(sm) {
        width: fit-content;
      }
    }
  }
}

.customer-detail {
  padding: 0 1rem 1rem 1rem;

  .content {
    margin: 0;

    &.allergic-alert {
      padding: 0.5rem;
      border-radius: $border-radius;
      margin: -0.5rem;

      &.is_allergy {
        background-color: rgba($red, 0.1);

        label {
          color: $red;
        }
      }
    }

    &.content-horizontal {
      padding: 0.5rem 0.75rem;
    }
  }
}

.nav-tab-container {
  padding: 0.5rem 1rem 0.75rem 1rem;
}

.fill-tab-content {
  // height: 100%;

  .tab-pane {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

// CARD
.card {
  .card-header {
    .card-header-label {
      color: $text-muted;
      width: 100%;
      margin-right: 0;
      display: flex;
      justify-content: space-between;

      @include media-breakpoint-up(sm) {
        margin-right: 1rem;
        width: auto;
        display: block;
      }

      @include media-breakpoint-up(lg) {
        width: auto;
        line-height: initial;
      }

      &.sm-inline {
        @include media-breakpoint-up(sm) {
          width: auto;
          margin-right: 1rem;
        }
      }

      &.w-50 {
        width: 100% !important;

        @include media-breakpoint-up(sm) {
          margin-right: 0;
          width: 50% !important;
          display: block;
        }
      }
    }
  }

  .card-body {
    &.opd-profile-load {
      min-height: 140px;
    }
  }
}

.custom-fill-dialog-content {
  .mat-dialog-container {
    display: flex;
    flex-direction: column;
  }
}

// CUSTOM
*:focus-visible {
  outline: none !important;
}

.auth-login-form {
  .form-control {
    font-family: unset !important;
  }
}

.info-list {
  margin-bottom: 1rem;

  label {
    color: $text-muted;
    margin-bottom: 0;
  }

  .data {
    font-family: $font-family-th-data;
    margin-bottom: 0;
    font-weight: $font-weight-normal;
  }
}

.allergic-card-wrapper {
  background-color: rgba($red, 0.1);

  .allergic-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    border-radius: 0.375rem;
    border: 1px solid rgba($danger, 0.5);
    background-color: $white;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    .allergic-content {
      text-align: left;
      padding: 0.5rem 0.75rem !important;
      border-bottom: 1px solid $border-color;

      &.cw-1 {
        grid-column: 1 / span 2;
        border-top: none;
      }

      &.bd-r {
        border-right: 1px solid $border-color;
      }

      &:last-child {
        border-bottom: 0;
      }

      .mobile-label {
        color: $text-muted;
        font-size: $font-size-sm;
      }
    }
  }
}

.datepicker-group {
  .form-control {
    padding-right: 0px;
    border-right: none;
  }

  .input-group-text {
    padding-left: 0px;

    &.disabled {
      background-color: $gray-100;
    }
  }

  &.error {
    .form-control,
    .input-group-text {
      border-color: $danger !important;
    }
  }
}

a {
  color: $body-color;
  transition: all 0.2s ease;

  &.primary {
    color: $primary;

    &:hover,
    &:active {
      color: darken($primary, 5%);
    }
  }
}

// FORM
.form-section {
  margin-bottom: 1.85rem;

  .form-sub-title {
    margin-top: 1rem;

    h4 {
      font-size: 1.143rem !important;
    }
  }
}

.full-form {
  display: flex;
  flex-direction: column;

  .form-upload {
    width: 150px;
    margin: 0 auto 1.25rem auto;

    .btn {
      width: 100%;
    }

    &.size-120 {
      width: 120px;
    }
  }

  .form-data {
    flex: 1;
  }

  .form-button-group {
    margin-top: 1.4rem;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;

    .form-upload {
      width: 150px;
      margin: 0 1.5rem 0 0;
    }
  }
}

.section-title {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  .feather {
    stroke-width: 3;
  }

  .btn {
    .feather {
      stroke-width: 2 !important;
    }
  }
}

// APP HEADER
.custom-breadcrumb {
  app-content-header {
    flex: 2;
  }

  .button-group {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    width: 100%;

    @include media-breakpoint-up(sm) {
      justify-content: flex-end;
      margin-top: 0;
    }

    .btn-sm-fw {
      flex: 1;
      justify-content: center;

      @include media-breakpoint-up(sm) {
        flex: unset;
      }
    }
  }
}

.document-button-action {
  .btn {
    width: 100%;
  }
}

.avatar-table {
  width: 34px;
  height: 34px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.25rem;
  border: 1px solid $gray-200;
}

.no-border-bottom {
  border-bottom: none !important;
}

.validate-pw-wrapper {
  margin-top: 2px;

  .validate-pw {
    display: flex;
    align-items: center;
    font-size: $font-size-sm;
    color: $red;
    transition: color 0.3s ease-in-out;

    &.valid {
      color: $green;
    }

    &:not(:last-child) {
      margin-bottom: 0.1rem;
    }

    .icon {
      display: none;
      margin-right: 0.5rem;

      &.show {
        display: block;
      }
    }

    p {
      line-height: 1.25;
      margin-top: 2px;
    }
  }
}

span.error {
  margin-top: 2px;
  color: $red;
  font-size: $font-size-sm;
}

.loading-overlay {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border-radius: $card-border-radius;
  overflow: hidden;

  .loading {
    // left: calc(50% - 25px);
    // top: calc(50% - 25px);
    width: 50px;
    height: 50px;
  }

  &.bg-semi {
    background-color: rgba($white, 0.75);
  }
}

.pre-line {
  white-space: pre-line;
}

::ng-deep {
  .progress-linear {
    position: relative !important;

    .container {
      max-width: 100% !important;
    }
  }
}

.cdk-overlay-container {
  z-index: 1053;
}

.version-text {
  margin: 0;
  font-family: $font-family-en;
  font-size: $font-size-sm;
  color: $text-muted;
}

.no-attachment-file {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dadadc;
  height: 62px;
}

@include media-breakpoint-up(xl) {
  .statistics-item {
    max-width: 20%;
  }
}

.custom-badge {
  padding: 1.5px 5px;
  border-radius: 4px;
  color: $blue-700;
  background-color: rgba($primary, 0.12);
}

.info-card {
  padding: 0.75rem 1rem;
  border-radius: $card-border-radius;
  background-color: rgba($primary-sub, 0.1);
  border: 1px solid $primary-sub;
  display: flex;
  column-gap: 0.5rem;

  svg {
    min-width: fit-content;
    margin-top: 3px;
  }

  &.basic {
    background-color: $gray-50;
    border-color: $gray-600;
  }
}

.ng-scroll-content {
  display: block !important;
}

// POS
$pos-rightbar-width: 460px;

.pos-contrainer {
  .module-title {
    padding-left: 0.75rem;
    border-left: 2px solid $border-color;
    font-size: 1.25rem;
    font-weight: $font-weight-bold;
    color: #29295f;
  }

  .pos-layout {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(xl) {
      flex-direction: row;

      .pos-content {
        width: calc(100% - $pos-rightbar-width);
      }

      .rigth-sidebar {
        width: $pos-rightbar-width;
        position: static !important;
      }
    }

    .sidebar-right,
    .sidebar {
      width: 100%;
    }

    .rigth-sidebar {
      position: absolute;

      .sidebar-header {
        background-color: $gray-50;

        .col-item {
          display: flex;
          flex-direction: column;
          padding: 0.75rem;
          column-gap: 0.5rem;
          border-top: 1px solid $border-color;
          border-bottom: 1px solid $border-color;

          @include media-breakpoint-up(sm) {
            flex-direction: row;
            align-items: center;
          }
        }
      }

      .sidebar-list {
        width: 100%;
        padding: 0.75rem;
        display: flex;
        flex-direction: column;
        overflow: auto;

        .list-header {
          display: flex;
          width: 100%;
          margin-bottom: 0.5rem;
          padding: 0 0.5rem;
        }

        .li-space {
          &:nth-child(1) {
            width: 40%;
          }

          &:nth-child(2) {
            width: 20%;
          }

          &:nth-child(3) {
            width: 20%;
          }

          &:nth-child(4) {
            width: 20%;
          }
        }

        .list-body {
          display: flex;
          flex-direction: column;

          .list-card {
            border: 1px solid #e5e7e9;
            border-radius: $border-radius;
            display: flex;

            .card-item {
              // min-height: 52px;
              width: calc(100% - 30px);

              &.read-only {
                width: calc(100%) !important;
              }
            }

            .card-detail {
              display: flex;
              align-items: center;
              padding: 0.5rem;
            }

            .card-discount {
              padding: 0.25rem 0.5rem;
              background-color: $gray-100;
              font-size: 13px;
              display: flex;
              align-items: center;
              justify-content: end;
            }

            // .card-action {
            //   color: #EA5455;
            //   font-size: 2rem;
            //   width: 30px;
            //   border-left: 1px solid #E5E7E9;
            //   display: flex;
            //   justify-content: center;
            //   padding-top: 10px;
            //   cursor: pointer;
            // }
          }
        }
      }

      .sidebar-summary {
        border-top: 1px solid $border-color;

        .content-space {
          padding: 0 0.75rem;
          height: 42px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid $border-color;
        }

        .footer-summary {
          padding: 0.75rem;
          display: flex;
          flex-direction: column;
          row-gap: 0.75rem;

          .footer-content {
            display: flex;
            justify-content: space-between;
            font-size: 1.5rem;
            font-weight: $font-weight-bold;
          }
        }
      }
    }

    @include media-breakpoint-down(lg) {
      .rigth-sidebar {
        .sidebar {
          .sidebar-pos {
            transform: translateX(100%);
            transition: all 0.25s ease;
            position: fixed;
            top: $navbar-height;
            right: 0;
            height: calc(100% - $navbar-height);
            width: $pos-rightbar-width;
            overflow-y: scroll;
            margin: 0;

            &.show {
              transition: all 0.25s ease;
              transform: translateX(0);
              box-shadow: -10px 4px 24px 0 rgba($black, 0.075);
            }
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      .sidebar-pos {
        width: 100% !important;
      }
    }
  }
}

.payment-type {
  padding: 0.65rem 1.25rem;
  border-radius: $border-radius;
  transition: all 0.2s ease-in-out;
  background-color: $gray-50;
  text-align: center;

  &:hover {
    cursor: pointer;
    background-color: $gray-100;
  }

  &.active {
    background: $primary;
    color: $white;
  }
}

.payment-input {
  position: relative;

  input {
    text-align: center;
    font-size: 1.25rem;
    font-weight: $font-weight-bolder;
    border-radius: $border-radius !important;
    padding-left: 58px !important;
    padding-right: 58px !important;

    &.is-invalid {
      padding-right: 58px !important;
    }
  }

  .input-group-append {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
  }
}
