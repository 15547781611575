@import '../../../bootstrap-extended/include'; // Bootstrap includes
@import '../../../components/include'; // Components includes

.ant-picker {
  width: 100%;
  padding: 0.536rem 1rem;
  border: $border-width solid $custom-control-border-color;
  border-radius: $input-border-radius;
  min-height: $input-height;

  .ant-picker-month-panel {
    width: 240px !important;
  }

  .ant-picker-input {
    input {
      line-height: $line-height-base;
      color: $body-color;
      font-size: 1rem;
      font-family: $font-family-th-data;

      &::placeholder {
        color: $input-placeholder-color;
      }
    }
  }

  &:hover {
    border-color: $custom-control-border-color;
  }

  &.ant-picker-focused {
    border-color: $primary;
    box-shadow: $input-focus-box-shadow;

    .ant-picker-input {
      input {
        &::placeholder {
          transform: translate(5px);
          transition: all 0.2s ease;
        }
      }
    }
  }

  &.is-invalid {
    border-color: $danger !important;
  }

  .ant-picker-suffix {
    color: $dark;
  }
}

.ant-picker-dropdown {
  color: $body-color;

  .ant-picker-panel-container {
    border-radius: $input-border-radius;
    box-shadow: $input-focus-box-shadow;

    .ant-picker-header {
      color: $body-color;

      button {
        color: $gray-500;
        line-height: 35px;

        &:hover {
          color: $primary;
        }

        .ant-picker-super-prev-icon,
        .ant-picker-super-next-icon {

          &::after {
            display: none;
          }
        }
      }
    }

    .ant-picker-header-view {
      line-height: 35px;

      button {
        color: $body-color;
      }

    }

    .ant-picker-content {
      height: 180px;

      th,
      td {
        color: $body-color;
      }

      .ant-picker-cell {
        .ant-picker-cell-inner {
          width: 100%;
          min-width: 28px;
          height: 28px;
          line-height: 28px;
          transition: none;
        }
      }

      .ant-picker-cell-in-view.ant-picker-cell-today {
        .ant-picker-cell-inner {
          &::before {
            border-color: $primary;
          }
        }
      }

      .ant-picker-cell-in-view.ant-picker-cell-selected {
        .ant-picker-cell-inner {
          background-color: $primary;
        }
      }
    }
  }
}