@import "@core/scss/base/bootstrap-extended/include"; // Components includes

.ngx-file-drop__drop-zone {
  border: dashed 2px $custom-control-border-color !important;
  background-color: $body-bg !important;
  border-radius: $border-radius !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 0.15s ease, background-color 0.15s ease-in-out;

  .ngx-file-drop__content {
    color: $body-color !important;
    transition: color 0.15s ease-in-out;
    height: 100% !important;
    width: 100%;
    position: relative;
  }
}

.drop-zone-preview {
  position: relative;
  min-height: 150px;

  .ngx-file-drop__drop-zone {
    min-height: 150px;
  }

  .preview {
    position: absolute;
    width: calc(100%);
    height: calc(100%);
    top: 0px;
    left: 0px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: $border-radius !important;
    background-color: $body-bg;

    .image-contain {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &.h-120 {
    min-height: 120px;

    .ngx-file-drop__drop-zone {
      min-height: 120px !important;
    }
  }
}

.drop-mode {
  .ngx-file-drop__drop-zone {
    height: 66px !important;
    // height: fit-content !important;
    padding: 0.75rem 0;
  }
}

.file-drop-over {
  .ngx-file-drop__drop-zone {
    border-color: $primary !important;
    background-color: rgba($primary, 0.05) !important;

    .ngx-file-drop__content {
      color: $primary !important;
    }
  }
}

.only-button {
  .ngx-file-drop__drop-zone {
    height: auto !important;
    min-height: unset;
    background-color: unset !important;
    border: none !important;
  }
}

.close {
  padding: 0.5rem !important;
  box-shadow: 0 5px 20px 0 rgba($red, 0.25);
  border-radius: $border-radius;
  background-color: $white !important;
  opacity: 1;
  transition: all 0.23s ease 0.1s;
  position: relative;
  transform: translate(6px, -6px);
  display: flex;
  justify-content: center;
  align-items: center;

  .feather {
    color: $body-color;
    transition: all 0.15s ease;
  }

  &:hover,
  &:focus,
  &:active {
    opacity: 1 !important;
    outline: none;
    transform: translate(5px, 0px);
    background-color: $body-bg !important;
    box-shadow: none;

    .feather {
      color: $red;
    }
  }
}