.report-application {

  .sidebar-detached {
    .sidebar {
      width: 240px;
    }
  }

  .report-wrapper {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(xl) {
      flex-direction: row;

      .report-sidebar-left {
        width: 240px;
      }

      .report-content {
        width: calc(100% - 240px);
      }
    }

    .report-sidebar-left {
      .sidebar-filter {
        margin-top: 0.85rem;
        width: $sidebar-width;
        z-index: 998;
        background-color: $white;
      }

      .sidebar-filter-content {
        border: 1px solid $border-color;
        border-radius: $border-radius;

        .sidebar-filter-header {
          padding: 0.75rem 1rem;
          border-bottom: 1px solid $border-color;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .sidebar-filter-body {
          .filter-content {
            .btn-link {
              padding: 0.75rem 1rem;
              display: flex;
              align-items: center;

              svg {
                transition: all 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955);
              }

              &.collapsed {
                &>svg {
                  transform: rotate(-90deg);
                }
              }
            }

            .collapse:not(:last-child) {
              border-bottom: 1px solid $border-color;
            }

            .card-body {
              padding: 0 1rem 0.75em 1rem;
            }
          }
        }

        .sidebar-filter-footer {
          border-top: 1px solid $border-color;
          padding: 0.75rem 1rem;
        }
      }

      .accordion {
        .card {
          overflow: visible !important;
        }
      }
    }
  }

  .body-content-overlay {
    position: fixed !important;
    z-index: 12 !important;
  }

  .stats-card {
    background-color: rgba($gray-100, 0.6);
    border: 1px solid $gray-100;

    .card-header {
      padding-top: 1rem;
      padding-bottom: 1rem;

      @include media-breakpoint-down(lg) {
        padding: 0.75rem 1rem;

        h2 {
          font-size: 1.286rem;
        }

        p {
          font-size: $font-size-sm;
        }
      }
    }

    .avatar {
      .avatar-content {
        width: 28px;
        height: 28px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .sidebar-filter-content {
      border: 0 !important;
      border-radius: 0 !important;
    }

    .sidebar-left {
      .sidebar {
        .card {
          border-radius: 0;
          margin: 0;
        }

        .sidebar-filter {
          border: 1px solid $border-color;
          border-radius: $border-radius;
          border-radius: $border-radius;
          transform: translateX(-112%);
          transition: all 0.25s ease;
          position: fixed;
          top: calc(#{$navbar-height} + 1rem);
          left: 1rem;
          height: calc(100% - #{$navbar-height + 1rem} - 1rem);
          overflow-y: auto;
          margin: 0;

          &.show {
            transition: all 0.25s ease;
            transform: translateX(0);
          }
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .body-content-overlay {
      position: fixed;
      opacity: 0;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      &.show {
        opacity: 1;
      }
    }

    &.horizontal-layout {
      .body-content-overlay {
        z-index: 998 !important;
      }

      .sidebar-filter {
        z-index: 999 !important;
      }
    }
  }
}