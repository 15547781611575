$responsive-sizes: (
  xs: '',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl',
);

$grid-sizes: (
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
  11: 11,
  12: 12,
  none: 'none'
);

.grid {
  display: grid;
}

@mixin grid-cols($responsive-size) {

  @each $grid-name,
  $grid-size in $grid-sizes {

    @if($responsive-size !='') {
      @if ($grid-size !='none') {
        .grid-cols-#{$responsive-size}-#{$grid-size} {
          grid-template-columns: repeat($grid-size, minmax(0, 1fr));
        }
      }

      @else {
        .grid-cols-#{$responsive-size}-#{$grid-size} {
          grid-template-columns: $grid-name;
        }
      }
    }

    @else {
      @if ($grid-size !='none') {
        .grid-cols-#{$grid-size} {
          grid-template-columns: repeat($grid-size, minmax(0, 1fr));
        }
      }

      @else {
        .grid-cols-#{$grid-size} {
          grid-template-columns: $grid-name;
        }
      }
    }
  }
}

@mixin grid-rows($responsive-size) {

  @each $grid-name,
  $grid-size in $grid-sizes {

    @if($responsive-size !='') {
      @if ($grid-size !='none') {
        .grid-rows-#{$responsive-size}-#{$grid-size} {
          grid-template-rows: repeat($grid-size, minmax(0, 1fr));
        }
      }

      @else {
        .grid-rows-#{$responsive-size}-#{$grid-size} {
          grid-template-rows: $grid-name;
        }
      }
    }

    @else {
      @if ($grid-size !='none') {
        .grid-rows-#{$grid-size} {
          grid-template-rows: repeat($grid-size, minmax(0, 1fr));
        }
      }

      @else {
        .grid-rows-#{$grid-size} {
          grid-template-rows: $grid-name;
        }
      }
    }
  }
}

@mixin col-span($responsive-size) {

  @each $grid-name,
  $grid-size in $grid-sizes {

    @if($responsive-size !='') {
      @if ($grid-size !='none') {
        .col-span-#{$responsive-size}-#{$grid-size} {
          grid-column: span $grid-size / span $grid-size;
        }
      }

      @else {
        .col-span-#{$responsive-size}-full {
          grid-column: 1 / -1;
        }

        .col-#{$responsive-size}-auto {
          grid-column: auto;
        }
      }
    }

    @else {
      @if ($grid-size !='none') {
        .col-span-#{$grid-size} {
          grid-column: span $grid-size / span $grid-size;
        }
      }

      @else {
        .col-span-full {
          grid-column: 1 / -1;
        }

        .col-auto {
          grid-column: auto;
        }
      }
    }
  }
}

@mixin row-span($responsive-size) {

  @each $grid-name,
  $grid-size in $grid-sizes {

    @if($responsive-size !='') {
      @if ($grid-size !='none') {
        .row-span-#{$responsive-size}-#{$grid-size} {
          grid-row: span $grid-size / span $grid-size;
        }
      }

      @else {
        .row-span-#{$responsive-size}-full {
          grid-row: 1 / -1;
        }

        .row-#{$responsive-size}-auto {
          grid-row: auto;
        }
      }
    }

    @else {
      @if ($grid-size !='none') {
        .row-span-#{$grid-size} {
          grid-row: span $grid-size / span $grid-size;
        }
      }

      @else {
        .row-span-full {
          grid-row: 1 / -1;
        }

        .row-auto {
          grid-row: auto;
        }
      }
    }
  }
}

// Gap
$gap: 1em !default;
$gaps: (
  ) !default;
$gaps: map-merge((0: 0,
      25: ($gap * 0.25),
      50: ($gap * 0.5),
      75: ($gap * 0.75),
      1: ($gap ),
      2: ($gap * 1.5),
      3: ($gap * 3),
      4: ($gap * 3.5),
      5: ($gap * 4)),
    $gaps
);

@mixin gap($responsive-size) {

  @each $gap-name,
  $gap-size in $gaps {

    @if($responsive-size !='') {
      .gap-#{$responsive-size}-#{$gap-name} {
        gap: $gap-size;
      }

      .gap-x-#{$responsive-size}-#{$gap-name} {
        column-gap: $gap-size;
      }

      .gap-y-#{$responsive-size}-#{$gap-name} {
        row-gap: $gap-size;
      }
    }

    @else {
      .gap-#{$gap-name} {
        gap: $gap-size;
      }

      .gap-x-#{$gap-name} {
        column-gap: $gap-size;
      }

      .gap-y-#{$gap-name} {
        row-gap: $gap-size !important;
      }
    }
  }
}

@each $name,
$size in $responsive-sizes {
  @if ($size !='') {
    @include media-breakpoint-up($name) {
      @include grid-cols($size);
      @include col-span($size);
      @include row-span($size);
      @include gap($size);
    }
  }

  @else {
    @include grid-cols($size);
    @include col-span($size);
    @include row-span($size);
    @include gap($size);
  }
}