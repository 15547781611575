// Angular Dialog
@use "sass:math";

.cdk-overlay-dark-backdrop {
  background: rgba($black, 0.25);
  background-color: rgba($black, 0.25);
}

.mat-dialog-container {
  padding: 0 !important;
  box-shadow: $box-shadow !important;
  border-radius: 0.7rem !important;
  overflow: auto !important;

  .modal-header {
    background-color: $body-bg;
    border-bottom: 1px solid $border-color;
    border-top-left-radius: $modal-content-border-radius;
    border-top-right-radius: $modal-content-border-radius;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0.75rem 1rem;
    align-items: center;
    // close button

    .modal-title {
      font-size: 1.145rem;
    }

    .btn-close {
      background-color: $white;
      border: none;
    }

    &.sticky {
      position: sticky;
      top: 0;
      z-index: 10;
    }
  }

  .modal-body {
    padding: 0;
    border-bottom-left-radius: $modal-content-border-radius;
    border-bottom-right-radius: $modal-content-border-radius;

    .modal-body-wrapper {
      padding: 1rem 1rem;
    }
  }

  .modal-footer {
    padding: 1rem 1rem;
    justify-content: flex-start;
    border-top: 1px solid $border-color;

    .btn {
      margin: 0;
    }
  }
}

.cdk-global-scrollblock {
  top: 0 !important;
}
