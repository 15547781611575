@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import '@core/scss/base/colors';
@import '@core/scss/base/components/include'; // Components includes

// variable declaration
$datatables-bg-header-color: $table-head-bg;
$datatables-text-color: $gray-400;
$datatables-border-color: $border-color;
$datatables-footer-text-color: $text-muted;
$datatables-dark-text-color: $theme-dark-body-color;
$datatables-dark-footer-bg-color: $theme-dark-card-bg;
$datatables-dark-border-color: $theme-dark-border-color;
$datatables-dark-header-bg-color: $theme-dark-table-header-bg;
$datatables-dark-body-bg-color: $theme-dark-table-bg;
$datatables-dark-active-bg-color: $theme-dark-card-bg;

.ngx-datatable {
  &.bootstrap {
    font-size: 1rem;

    &.core-bootstrap {
      &.height-70-vh {
        height: 70vh !important;
      }

      .datatable-header {
        background-color: $datatables-bg-header-color;
        text-transform: uppercase;
        border-top: 1px solid $datatables-border-color;
        border-bottom: 1px solid $datatables-border-color;
        width: 100% !important;

        .datatable-header-cell {
          border-bottom: 0;
          padding: 10px 10px;
          font-weight: $font-weight-bold;
          font-size: 1rem;
        }

        // sort-icon positioning
        .datatable-header-cell-template-wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .sort-btn {
          &:before {
            font-size: 1.3rem;
          }
        }
      }

      .datatable-body-row {
        border-top: none;
        border-bottom: 1px solid $datatables-border-color;

        &.datatable-row-even {
          background-color: initial;
        }

        &.active {
          background-color: $gray-50;
          color: $body-color;
        }

        .datatable-body-cell-label {
          width: 100%;

          .cell-line-height {
            line-height: 1.25;
          }
        }

        .datatable-body-cell {
          padding: 10px 10px;
          display: flex;
          align-items: center;
          font-family: $font-family-th-data;
        }
      }

      .empty-row {
        display: none;
      }

      .datatable-summary-row {
        .datatable-body-row {

          .datatable-row-center {
            .datatable-body-cell:first-child {
              overflow: visible;
              white-space: nowrap !important;
            }
          }

          *:not(.datatable-row-left),
          *:not(.datatable-row-right) {
            .datatable-body-cell {
              background-color: rgba($brand, 0.15);
            }
          }

        }
      }

      .datatable-footer {
        background-color: transparent;
        color: $datatables-text-color;
        overflow: hidden;
        border-radius: 0 0 $card-border-radius $card-border-radius !important;

        .datatable-pager {
          flex: 0;
          margin: 0 0 0 1rem;

          a {
            color: $datatables-text-color;
            line-height: 20px;
          }

          ul {
            display: flex;
            width: fit-content;
            margin-left: auto;

            li:not(.disabled) {

              &.active,
              &.active:hover {
                a {
                  background-color: $primary !important;
                  color: $white !important;
                }
              }

              &:hover {
                a {
                  background-color: transparent !important;
                  color: $primary !important;
                }
              }
            }

            li {
              margin: 0px !important;

              a {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              &:not(.disabled):hover {
                a {
                  background-color: $white;
                  color: $datatables-text-color;
                }
              }

              &.disabled {
                a {
                  color: $text-muted;
                }
              }

              i {
                font-size: 22px;
                line-height: 1;
                height: 22px;
              }

              &:first-child,
              &:last-child {
                display: none;
              }
            }
          }

          .pager {
            background-color: $pagination-bg;
            border-radius: 99px;
            height: 36px;
            padding: 2px;
          }
        }

        .page-count {
          color: $datatables-footer-text-color;
        }

        .datatable-footer-inner {
          height: auto !important;

          @include media-breakpoint-down(sm) {
            align-items: flex-start;
          }
        }

        .datatable-footer-custom {
          flex: 1;
          padding: 0.75rem 1.5rem;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          background-color: $white;

          .datatable-footer-row-fetch {
            min-width: 65px;
          }
        }
      }

      &.header-height-50 {
        .datatable-header {
          height: 50px !important;
        }
      }

      .datatable-body-cell {
        white-space: normal !important;

        // .datatable-body-cell-label {
        //   overflow: hidden;
        //   white-space: nowrap;
        //   text-overflow: ellipsis;
        //   width: 100%;
        // }
      }
    }

    .datatable-body {
      min-height: 5px;

      .progress-linear {
        display: block;
        width: 100%;
        height: 5px;
        padding: 0;
        margin: 0;
        position: absolute;
        z-index: 99;

        .container {
          display: block;
          position: relative;
          overflow: hidden;
          max-width: unset;
          width: 100%;
          height: 5px;
          transform: translate(0, 0) scale(1, 1);
          background-color: $custom-1;
          padding: 0;

          .bar {
            animation: query 1.75s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
            transition: transform .2s linear;
            background-color: $primary;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 5px;
          }
        }
      }
    }
  }

  .datatable-scroll {
    width: unset !important;
  }

  &.fixed-thead {
    overflow: unset !important;

    .datatable-header {
      position: sticky;
      top: 0;
      z-index: 12;
    }
  }

  &.fixed-footer {
    @include media-breakpoint-up(sm) {
      .datatable-footer {
        position: sticky;
        bottom: -1px;
        left: 0;
        z-index: 11;
      }
    }
  }
}

@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(-50%) scale(0.3, 1);
  }

  100% {
    opacity: 0;
    transform: translateX(100%) scale(0, 1);
  }
}

.custom-empty-row {
  position: relative;
  padding: 1.5rem 1rem;
  margin-bottom: 0;
  text-align: center;
  color: $text-muted;
}

// media query for Small & Medium devices
@media (max-width: 576px) {
  .datatable-footer-custom {
    padding: 0.75rem 1rem !important;
  }
}

@media (max-width: 768px) {
  .page-count {
    display: none;
  }

  .datatable-pager {
    text-align: center !important;
  }
}

@media screen and (max-width: 800px) {
  .desktop-hidden {
    display: initial;
  }

  .mobile-hidden {
    display: none;
  }
}

@media screen and (min-width: 800px) {
  .desktop-hidden {
    display: none;
  }

  .mobile-hidden {
    display: initial;
  }
}

// dark layout style
.dark-layout {

  .datatable-header-inner,
  .datatable-header {
    background-color: $datatables-dark-header-bg-color !important;
    border-top: 1px solid $datatables-dark-border-color !important;
  }

  .ngx-datatable.bootstrap.core-bootstrap {
    .datatable-footer {
      background-color: $datatables-dark-footer-bg-color !important;

      .datatable-pager ul li.disabled a {
        color: $datatables-text-color;
      }

      .datatable-pager a {
        color: $datatables-dark-text-color;
      }
    }
  }

  .datatable-body-row {
    border-color: $datatables-dark-border-color !important;

    &.active {
      background-color: $datatables-dark-active-bg-color !important;
      color: inherit !important;
    }
  }

  .datatable-body {
    background-color: $datatables-dark-body-bg-color !important;
  }

  .pager {
    background-color: $theme-dark-pagination-bg !important;
  }
}