// var for box shadow of gradient buttons
// Buttons
.btn {
  box-shadow: none;
  // font-weight: 400;
  transition: all 0.2s ease, margin 0s ease;
  line-height: 1.5;
  padding: 0.65rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;

  // gradient button on hover transition
  &[class*='bg-gradient-'] {
    transition: all 0.2s ease;

    &:hover {
      transform: translateY(-2px);
    }
  }

  &.mw-120 {
    min-width: 120px;
  }

  &.mw-130 {
    min-width: 130px;
  }

  &.no-shadow {
    &:hover {
      box-shadow: none !important;
    }
  }

  // Icon button padding
  &.btn-icon {
    padding: 0.75rem 0.736rem;
  }

  &.btn-sm {
    line-height: 1.715rem;
    padding: 0.145rem 1rem;

    &.btn-icon {
      padding: 0.5rem;
      line-height: 0.5;
    }

    &.w-icon {
      display: flex;
      align-items: center;
      padding: 0.145rem 0.75rem;
    }

    &.btn-sm-filter {
      font-size: 15px;
      line-height: 1.5;
    }
  }

  &.btn-lg {
    &.btn-icon {
      padding: 1rem;
      line-height: 0.75;
    }
  }

  &.btn-light {
    background-color: $gray-100 !important;
    border-color: $gray-100 !important;
    color: $dark;

    &:focus,
    &:active,
    &.active {
      background-color: darken($gray-100, 5%) !important;
    }

    &:hover:not(.disabled):not(:disabled) {
      background-color: darken($gray-100, 5%) !important;
    }

    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
    }
  }

  &.btn-sub-primary {
    background-color: $body-bg;
    color: $green;

    &:focus,
    &:active,
    &.active {
      color: $green;
      background-color: darken($body-bg, 5%);
    }

    &:hover:not(.disabled):not(:disabled) {
      color: $green;
      background-color: darken($body-bg, 5%);
      // box-shadow: 0 8px 25px -8px rgba($color-value, 1);
    }

    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
    }
  }

  &.btn-outline-cancel {
    background-color: $white !important;
    color: $body-color !important;
    border-color: $custom-control-border-color !important;

    &:focus,
    &:active,
    &.active {
      background-color: $gray-50 !important;
    }

    &:hover:not(.disabled):not(:disabled) {
      background-color: $gray-50 !important;
    }

    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none !important;
    }
  }

  &.btn-outline-dash {
    border-style: dashed !important;
  }

  &.btn-file {
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: $custom-1 !important;
    color: $primary !important;
    border-color: $custom-1 !important;
    font-weight: $font-weight-normal !important;

    &:hover:not(.disabled):not(:disabled) {
      box-shadow: 0 8px 25px -8px $custom-1;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):focus {
      background-color: $custom-1 !important;
      color: $primary !important;
      border-color: $custom-1 !important;
    }
  }

  &.btn-outline-primary {
    background-color: $white !important;
    color: $primary !important;
    border-color: $primary !important;

    &:focus,
    &:active,
    &.active {
      background-color: $custom-1 !important;
    }

    &:hover:not(.disabled):not(:disabled) {
      background-color: $gray-50 !important;
    }

    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none !important;
    }
  }

  &.btn-none-bg {
    background: none !important;
  }

  &.w-icon {
    display: flex;
    padding: 0.536rem 1.286rem;
    align-items: center;
  }

  &:focus,
  &.focus,
  &:active,
  &.active {
    outline: none;
    box-shadow: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    box-shadow: none !important;
  }

  // feather icons inside btn
  .feather {
    height: 17px;
    width: 17px;
    font-size: 1.2rem;
    vertical-align: middle;
  }
}

// For Waves Input Padding
.btn.waves-input-wrapper {
  padding: 0;
}

@include media-breakpoint-down(sm) {
  .btn-sm-block {
    display: block;
    width: 100%;
  }
}

// Remove cursor-pointer from button if button is disabled
// * setting it to inherit will auto adept cursor
.waves-effect {
  cursor: inherit;
}

.btn-collapse {
  .feather {
    transition: all 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955);
  }

  &.rotate-up {
    .feather {
      transform: rotateX(180deg);
    }
  }

  &.btn-collapse-slim {
    line-height: $line-height-sm;
  }
}

// CUSTOM
.btn-back {
  margin-right: 1rem;
  background-color: $white !important;
  padding: 0.43rem !important;

  .feather {
    width: 20px;
    height: 20px;
  }
}

.btn-close {
  padding: 0 !important;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .feather {
    width: initial;
    height: initial;
  }
}