/*================================================================================
THEME COMPONENT
================================================================================*/
@import "@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/base/pages/page-misc.scss";
@import "@core/scss/base/pages/app-calendar.scss";
@import "@core/scss/angular/libs/datatables.component.scss";
@import "@core/scss/angular/libs/select.component.scss";
@import "@core/scss/angular/libs/ng-zorror.component.scss";
@import "@core/scss/angular/libs/file-uploader.component.scss";
@import "@core/scss/angular/libs/sweet-alerts.component.scss";
@import "@core/scss/angular/libs/date-time-picker.component.scss";

@import './utilities/utilities.scss';

@import './components/components.scss';

.animate__animated {
  --animate-duration: 0.4s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }
}

/*================================================================================
LIBS COMPONENT
================================================================================*/
@import "~ng-zorro-antd/style/index.min.css";
@import "~@fullcalendar/daygrid/main.css";

/*================================================================================
CUSTOM COMPONENT
================================================================================*/
@import "./components/opds.scss";
@import "./components/reports.scss";

/*================================================================================
CUSTOM & OVERRIDE STYLE
================================================================================*/